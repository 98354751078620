import React, { useContext, useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { sidebarData } from "../constraints/constants";
import { BiLogOutCircle } from "react-icons/bi";
import { AiOutlineGlobal } from "react-icons/ai";
import AuthContext from "../context/AuthContext";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import {
  DepositWithrawData,
  DepositWithrawDataArray,
} from "../Utils/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { isEmpty, toLower } from "lodash";
import { useNavigate } from "react-router-dom";
import DepositIcon from "../assets/icons/icon-deposit.svg";
import iconWithdrawal from "../assets/icons/icon-withdrawal.svg";
import iconBetReacord from "../assets/icons/icon-bet-records.svg";
import iconTurnover from "../assets/icons/icon-turnover.svg";
import iconRecords from "../assets/icons/icon-records.svg";
import iconWhatsapp from "../assets/icons/icon-whatsapp.svg";
import iconEmail from "../assets/icons/icon-email.svg";
import iconFb from "../assets/icons/icon-facebook-messenger.svg";
import iconLogout from "../assets/images/header-logout-icon.svg";
import iconProfile from "../assets/icons/icon-info.svg";
import { HiOutlineRefresh } from "react-icons/hi";

import iconLogins from "../assets/icons/icon-login-s.svg";
import iconRegister from "../assets/icons/icon-register.svg";
import iconBonuses from "../assets/icons/icon-bonuses.svg";
import iconPromotion from "../assets/icons/icon-promotion.svg";
import iconDownload from "../assets/icons/icon-download.svg";
import iconCustomer from "../assets/icons/icon-customer.svg";
import iconHome from "../assets/icons/icon-home.svg";
import iconLogin from "../assets/icons/icon-login.svg";
import iconArrow from "../assets/icons/icon-arrow.svg";

const MenuSidebarNew = () => {
  let {
    logoutUser,
    setShowSidebar,
    showSidebar,
    lang,
    user,
    setEn,
    setBr,
    launchEGTCasino,
    launchCasino,
    withoutLogin,
    amounutRefresh,
    refreshAmountLoader,
    userCoins,
    handleShowLoginNotification,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const setFunc = (type) => {
    setData(
      DepositWithrawDataArray?.find((res) => {
        {
          return res?.key == type;
        }
      })
    );
    setKey(type);
  };
  const [data, setData] = useState({});
  const [activeClass, SetActiveClass] = useState("");
  const [key, setKey] = useState("");
  const [account, setAccount] = useState(false);

  useEffect(() => {
    // setData(
    //   DepositWithrawDataArray?.find((res) => {
    //     {
    //       return res?.key == "sports";
    //     }
    //   })
    // );

    window.addEventListener("scroll", () => {
      if (window.scrollY > 180) {
        SetActiveClass("gamefixed");
      } else {
        SetActiveClass("");
      }
    });
  }, []);
  const { t } = useTranslation();

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowSidebar(false);
      }}
    >
      <>
        <button
          className="bg-transparent border-0 p-0 close-b"
          onClick={() => setShowSidebar(false)}
        >
          <RxCross2 />
        </button>

        {!account ? (
          <div className="sidebar-wrapper slide-right">
            <div className="menu-top ">
              <div className="menu-header ">
                <div className="member-name ">
                  {isEmpty(user) ? (
                    <span className="">Hi Welcome</span>
                  ) : (
                    <>
                      <div
                        id="account"
                        className="account "
                        onClick={() => setAccount(true)}
                      >
                        <span className="">{user?.user?.username}</span>
                      </div>
                      <p id="profile_text" className="">
                        {" "}
                        Profile{" "}
                      </p>
                      <div
                        className="icon-arrow "
                        onClick={() => setAccount(true)}
                      >
                        <img src={iconArrow} />
                      </div>
                    </>
                  )}
                </div>
              </div>
              {isEmpty(user) ? (
                <div className="beforelogin ">
                  <div className="login-button ">
                    <span style={{ backgroundImage: iconLogins }} className="">
                      {" "}
                      <img src={iconLogins} height="16" />
                    </span>
                    <Link className="" to="/login">
                      LOGIN
                    </Link>
                  </div>
                  <div className="register-button ">
                    <span className="">
                      <img src={iconRegister} height="16" />
                    </span>
                    <Link className="" to="/register">
                      SIGN UP
                    </Link>
                  </div>
                </div>
              ) : (
                <>
                  <div className="afterlogin marquee ">
                    <div className="main-wallet ">
                      <div className="wallet ">
                        {" "}
                        Main Wallet
                        <div onClick={amounutRefresh}>
                          <HiOutlineRefresh
                            size={20}
                            className={
                              refreshAmountLoader ? "refresh-icon" : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="amount ">
                        <span data-currency="$" className="amount ">
                          <i className="">৳ {userCoins?.balance?.toFixed()}</i>
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {!isEmpty(user) && (
              <ul className="wallet-box ">
                <li
                  data-category="deposit"
                  className=""
                  tabindex="0"
                  ot-tag="menu_Deposit_T"
                  onClick={() => navigate("/deposit")}
                >
                  <Link className="" to="/deposit">
                    <span className="">
                      <img src={DepositIcon} />
                    </span>
                    <p className="">Deposit</p>
                  </Link>
                </li>
                <li
                  data-category="withdrawal"
                  className=""
                  ot-tag="menu_Withdrawal_T"
                  onClick={() => navigate("/withdraw")}
                >
                  <Link className="" to="/withdraw">
                    <span className="">
                      <img src={iconWithdrawal} />
                    </span>
                    <p className="">Withdrawal</p>
                  </Link>
                </li>
              </ul>
            )}
            <ul className="slide-top">
              {DepositWithrawData?.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      setFunc(item?.type);
                    }}
                    className={`${key == item?.type ? "active" : ""}`}
                  >
                    <figure>
                      <img
                        src={
                          key == item?.type
                            ? item?.menuactiveicons
                            : item?.activeicons
                        }
                        alt=""
                      />
                    </figure>
                    <a className="text-decoration-none">
                      {" "}
                      <span>{lang == "bn" ? item?.titlebn : item?.title}</span>
                    </a>
                  </li>
                );
              })}
            </ul>

            <ul className="item-box slide-top">
              <li className="">
                <figure>
                  {" "}
                  <img src={iconPromotion} alt="" />
                </figure>
                <Link to="/" className="text-decoration-none">
                  {" "}
                  <span>Promotions</span>
                </Link>{" "}
              </li>
              <li>
                <figure>
                  {" "}
                  <img src={iconDownload} alt="" />
                </figure>
                <Link to="/login" className="text-decoration-none">
                  {" "}
                  <span>Download</span>
                </Link>
              </li>
            </ul>
            <ul className="contact-box align-center">
              <li
                onClick={() => {
                  window.open("https://wa.me/", "_self");
                }}
              >
                <figure>
                  {" "}
                  <img src={iconWhatsapp} alt="" />
                </figure>
                <Link to="/" className="text-decoration-none">
                  {" "}
                  <span>WhatsApp</span>
                </Link>{" "}
              </li>

              <li
                onClick={() => {
                  window.open("https://wa.me/", "_self");
                }}
              >
                <figure>
                  {" "}
                  <img src={iconFb} alt="" />
                </figure>
                <Link to="/" className="text-decoration-none">
                  {" "}
                  <span>Facebook Messanger</span>
                </Link>{" "}
              </li>

              <li
                onClick={() => {
                  window.open("https://wa.me/", "_self");
                }}
              >
                <figure>
                  {" "}
                  <img src={iconEmail} alt="" />
                </figure>
                <Link to="/" className="text-decoration-none">
                  {" "}
                  <span>Support Email</span>
                </Link>{" "}
              </li>

              <li
                onClick={() => {
                  window.open("https://wa.me/", "_self");
                }}
              >
                <figure>
                  {" "}
                  <img src={iconEmail} alt="" />
                </figure>
                <Link to="/" className="text-decoration-none">
                  {" "}
                  <span>Marketing Email</span>
                </Link>{" "}
              </li>
            </ul>
            <ul className="home-box">
              <li onClick={() => navigate("/")}>
                <figure>
                  {" "}
                  <img src={iconHome} alt="" />
                </figure>
                <Link to="/" className="text-decoration-none">
                  {" "}
                  <span>{"Home"}</span>
                </Link>{" "}
              </li>
              {!isEmpty(user) ? (
                <li onClick={() => logoutUser()}>
                  <figure>
                    {" "}
                    <img src={iconLogin} alt="" />
                  </figure>
                  <Link to="#" className="text-decoration-none ">
                    {" "}
                    <span>{t("Logout")}</span>
                  </Link>
                </li>
              ) : (
                <li onClick={() => navigate("/login")}>
                  <figure>
                    {" "}
                    <img src={iconLogin} alt="" />
                  </figure>
                  <Link to="/login" className="text-decoration-none">
                    {" "}
                    <span>{t("Login")}</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        ) : (
          <div className="member-menu-content sidebar-menu">
            <button
              className="bg-transparent border-0 p-0 close-b"
              onClick={() => setShowSidebar(false)}
            >
              <RxCross2 />
            </button>
            <div className="menu-header ">
              <div id="account" className="account ">
                <span className="">{user?.user?.username}</span>
              </div>
            </div>

            <div className="member-menu-box balance-box">
              <div className="balance balance-row">
                <div className="text">
                  {" "}
                  Main Wallet{" "}
                  <div className="icon refresh">
                    <div onClick={amounutRefresh}>
                      <HiOutlineRefresh
                        size={20}
                        className={refreshAmountLoader ? "refresh-icon" : ""}
                      />
                    </div>
                  </div>
                  <div className="icon eyes"></div>
                </div>
                {refreshAmountLoader ? (
                  <div className="loader-outer">
                    <span
                      className="loader-inner-icon"
                      style={{ animationDelay: "0s" }}
                    ></span>
                    <span
                      className="loader-inner-icon"
                      style={{ animationDelay: ".1s" }}
                    ></span>
                    <span
                      className="loader-inner-icon"
                      style={{ animationDelay: ".2s" }}
                    ></span>
                    <span
                      className="loader-inner-icon"
                      style={{ animationDelay: ".3s" }}
                    ></span>
                    <span
                      className="loader-inner-icon"
                      style={{ animationDelay: ".4s" }}
                    ></span>
                    <span
                      className="loader-inner-icon"
                      style={{ animationDelay: ".5s" }}
                    ></span>
                    <span
                      className="loader-inner-icon"
                      style={{ animationDelay: ".6s" }}
                    ></span>
                    <span
                      className="loader-inner-icon"
                      style={{ animationDelay: ".7s" }}
                    ></span>
                  </div>
                ) : (
                  <>
                    <span className="amount totalBalanceWallet">
                      <i>
                        <i id="locale-util-dicrective-0">
                          BDT {userCoins?.balance?.toFixed()}
                        </i>
                      </i>
                    </span>
                  </>
                )}

                <span id="balanceHeader">-</span>
              </div>
            </div>

            <div className="member-menu-box member-list">
              <div className="title">
                <h2>
                  <span>Funds</span>
                </h2>
              </div>
              <ul className="align-center">
                <li className="deposit" onClick={() => navigate("/deposit")}>
                  {" "}
                  <a>
                    <span className="item-icon">
                      <img src={DepositIcon} />
                    </span>
                    <p>Deposit</p>
                  </a>
                </li>
                <li
                  className="withdrawal"
                  onClick={() => navigate("/withdraw")}
                >
                  {" "}
                  <a>
                    <span className="item-icon">
                      <img src={iconWithdrawal} />
                    </span>
                    <p>Withdrawal</p>
                  </a>
                </li>
              </ul>
            </div>
            <div className="member-menu-box member-list">
              <div className="title">
                <h2>
                  <span>History</span>
                </h2>
              </div>
              <ul className="align-center">
                <li className="total" onClick={() => navigate("/bets-history")}>
                  <a>
                    <span className="item-icon">
                      <img src={iconBetReacord} />
                    </span>
                    <p>Betting Records</p>
                  </a>
                </li>
                <li
                  className="water"
                  onClick={() => navigate("/profit-and-loss")}
                >
                  <a>
                    <span className="item-icon">
                      <img src={iconTurnover} />
                    </span>{" "}
                    <p>Profit & Loss</p>
                  </a>
                </li>
                <li
                  className="history"
                  onClick={() => navigate("/account-statement")}
                >
                  <a>
                    <span className="item-icon">
                      <img src={iconRecords} />
                    </span>
                    <p>Transaction Records</p>
                  </a>
                </li>
              </ul>
            </div>
            <div className="member-menu-box member-list">
              <div className="title">
                <h2>
                  <span>Profile</span>
                </h2>
              </div>
              <ul>
                <li className="account" onClick={() => navigate("/my-profile")}>
                  <a>
                    <span className="item-icon">
                      <img src={iconProfile} />
                    </span>
                    <p>Personal Info</p>
                  </a>
                </li>
                {/* <li className="resetpasswords"><a><span className="item-icon" ></span><p >Reset password</p></a></li>
                              <li className="mail"><a><span className="item-icon" ></span> <p >Inbox</p><span className="notice">8</span></a></li>
                              <li className="recommendfriend"><a><span className="item-icon" ></span><p >Referral</p></a></li> */}
              </ul>
            </div>

            <div className="member-menu-logout">
              <a>
                {/* <span className="item-icon me-2" ><img src={iconLogout}/>
                          </span> */}
                <div className="text" onClick={() => logoutUser()}>
                  <p>Log out</p>
                </div>
              </a>
            </div>
          </div>
        )}

        {key != "" && !account && (
          <div className="menu-second">
            <ul className="menu-second-ul active">
              {data?.array?.map((res) => {
                return (
                  <li
                    className=""
                    onClick={() => {
                      if (!isEmpty(user)) {
                        if (res?.gameTypeCheck == "sports") {
                          window.open(res?.link, "_self");
                        } else {
                          if (!isEmpty(user)) {
                            // let subCategory=res?.platForm=="EVOLUTION"?"EVO":res?.platForm=="SEXYBCRT"?"SEXY":res?.platForm;
                            // navigate("/casino?header="+toLower(res?.key)+"&subCasino="+subCategory)
                            if (res?.gameTypeCheck == "platForm") {
                              launchCasino({
                                platForm: res?.platForm,
                                gameType: res?.gameType,
                                casinoType: res?.casinoType,
                                isLobbyTrue: res?.isLobbyTrue,
                              });
                            } else if (res?.gameType == "egtGame") {
                              launchEGTCasino({
                                platForm: res?.platForm,
                                gameType: res?.gameTypeNew,
                                casinoType: res?.casinoType,
                              });
                            } else if (!isEmpty(res?.gameid)) {
                              launchEGTCasino({
                                platForm: res?.platForm,
                                gameType: res?.gameType,
                                casinoType: res?.casinoType,
                                gameid: res?.gameid,
                              });
                            } else {
                              if (
                                res?.platForm !== "" &&
                                res?.gameType !== "" &&
                                res?.casinoType !== ""
                              ) {
                                launchCasino({
                                  platForm: res?.platForm,
                                  gameType: res?.gameType,
                                  casinoType: res?.casinoType,
                                  isLobbyTrue: res?.isLobbyTrue,
                                });
                              }
                            }
                          } else {
                            handleShowLoginNotification();
                          }
                        }
                      } else {
                        handleShowLoginNotification();
                      }
                    }}
                  >
                    <figure>
                      <img src={res?.image} alt="" />
                    </figure>
                    <p>{res?.title}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </>
    </OutsideClickHandler>
  );
};

export default MenuSidebarNew;
