import React, { useContext,useEffect } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import FlagIcon from "../assets/imagesNew/india-icon.png";
import FlagIconBn from "../assets/imagesNew/bangladesh-flag-round-circle-icon.png";
import { isEmpty } from "lodash";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import { DepositWithrawData, DepositWithrawDataNew ,bottomNavigation} from "../Utils/constants";
import HomeIcon from "../assets/images/toolbar-icon-home.svg";
import PromotionsIcon from "../assets/images/toolbar-icon-promotion.svg";
import DepositIcon from "../assets/images/toolbar-icon-deposit.svg";
import AccountIcon from "../assets/images/toolbar-icon-mine.svg";
import Registration from "../Auth2/Registration";
import BetSlipContext from "../context/BetSlipContext";
import MyBets from "./MyBets";
import betsIcon from "../assets/images/mybets.png";

const FooterNew = () => {
  const { user, lang, setEn, setBr,handleShowLoginNotification  } = useContext(AuthContext);
  let location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showBet, setShowBet } = useContext(BetSlipContext);

  return (
    <>
  {!isEmpty(user) ? (
    <div className="footer-new">

      <ul>



          <>
          {bottomNavigation && bottomNavigation?.map((item, index) => {
              return (
            <li className={location.pathname === item?.link ? "active" : ""}
            onClick={() => {
              // if (item?.id == 5) {
              //   if (!isEmpty(user)) {
              //     setShowBet(true);
                 
              //   } else {
              //     handleShowLoginNotification();
              //   }
              // } else {
                navigate(item?.link);
              // }
            }}>
              <div className="payment-box footer-payment-box ">
                <figure>
                  <img src={item?.icon} />
                </figure>
                <span>{item?.menu}</span>
              </div>
            </li>
              );
          })}
           
          </>
        
      </ul>
      {showBet && <MyBets setShowBet={setShowBet} />}
    </div>
    ):""}
    </>
  );
};

export default FooterNew;
